@media (max-width: 767px) {
  .header-content {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .header-nav .navbar {
    padding: 0;
    margin: 0;
  }

  .service-box h3 {
    font-size: 13px;
    margin-bottom: 6px;
  }

  .service-box {
    height: 28.8vw;
  }

  .header-logo img {
    max-height: 40px;
  }

  .navbar-collapse .nav-item > .nav-link,
  .navbar-collapse .dropdown-item {
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .footer > .container > .row > * {
    padding-top: 20px;
    padding-bottom: 15px;
  }

  h3 {
    font-size: 18px;
  }
  .page-content .section {
    margin-top: 40px;
  }
  h1,
  .h1 {
    font-size: 25px !important;
    line-height: 30px;
  }
  .h-sub {
    font-size: 13px;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
  }
  .service-box-caption {
    display: block;
    position: relative;
    right: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
  }
  .slide-txt1 {
    font-size: 20px;
  }
  .slide-btn {
    display: none;
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .video-link {
    position: static;
    padding: 0px;
    border: 1px solid #ededed;
    background: #f5f5f5;
    margin: 16px 0 0px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .searching input[type="text"] {
    width: 100px;
  }
}

@media (min-width: 576px) {
  .custom-model {
    max-width: 610px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .chat-sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17.5rem;
    flex: 0 0 17.5rem;
    max-width: 17.5rem;
  }
}

@media (min-width: 768px) {
  .chat-sidebar {
    position: relative;
    height: 100%;
    top: 0;
    left: 0 !important;
    width: auto;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

@media (max-width: 1023px) {
  .header-content.stuck .navbar-toggler {
    margin-top: 0;
    top: 50% !important;
    transform: translateY(-50%);
  }
  .slide-txt1 {
    font-size: 30px;
    line-height: 1.16em;
  }
  .reviews-photo img {
    max-height: 450px;
  }
  .reviews-photo {
    overflow: hidden;
  }
  .navbar-toggler {
    display: block;
  }

  .header .navbar-nav li {
    border-top: 1px solid #f5f5f5;
    padding-right: 0;
    padding-left: 0;
  }

  .header .navbar-nav li:first-child {
    border-top: none;
  }

  .navbar-toggler:focus,
  .navbar-toggler:hover {
    outline: none;
  }
  .footer > .container > .row > * {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .header-content.stuck .navbar {
    position: absolute;
    left: 0;
    right: 0;
  }
  .header-nav .navbar {
    padding: 0;
    margin: 0;
  }
  .header-logo,
  .header-logo img {
    max-width: 120px;
    max-height: 60px;
  }
  .page-content .section {
    margin-top: 30px;
  }
  .custom-btn {
    font-size: 13px;
    line-height: 18px;
    padding: 10px 23px;
    border-radius: 21px;
    letter-spacing: 0;
  }
  .faq-wrap {
    padding: 25px 15px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .faq-item [data-toggle="collapse"] {
    font-size: 15px;
    line-height: 20px;
    padding: 13px 60px 13px 13px;
  }
  .reviews-wrap {
    padding: 27px 20px;
  }
  .counter-box-2 {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) {
  body:not(.touch) .quickLinks .col:hover .link-drop,
  body.touch .col.hovered .link-drop {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%) !important;
    transition-delay: 0s, 0s, 0s, 0.3s;
    pointer-events: auto;
  }

  .quickLinks.stuck .col:hover .link-drop {
    top: 100%;
  }
}

@media (max-width: 1229px) {
  .service-box p {
    display: none;
  }
  .service-box-caption p {
    display: none;
  }
  .service-box-caption.w-50 {
    width: 41% !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .service-box {
    height: 24vw;
    min-height: 230px;
  }
  .banner-left {
    min-height: 40vw;
    max-height: 100vh;
    background-position: center 20%;
  }
  .bg-fullheight {
    background-size: 100% auto;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .service-box .service-box-title {
    font-size: 18px;
  }
  .service-box-title {
    font-size: 18px;
  }
}
