// Variable overrides

// Units size Variables

$base-size: 14;

@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

$base-font-size: size($base-size);

$smallMobileWidth: '320px';

@mixin smallMobile {
  @media (max-width: #{$smallMobileWidth}) {
    @content;
  }
}

// Units size Variables

// Font Families
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$familyPrimary: 'Poppins', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$familySecondary: 'Open Sans', sans-serif;

// Font Families

// Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;
// Font Weights

// Frontend Colors //

// Background Colors
$header_top_contact:#1e76bd;
$whiteBackground:#fff;
$serviceBackground: #f7f7f9;
$virtualGradientColor: linear-gradient(to right, #ccd0ce , #f7f7f9);
$chooseUsGradientColor: linear-gradient(to right, #1e76bd 0%, #469ce2 100%);
$darkblueColor: #1a5188;
// Text Colors
$whiteTextColor:#fff;
$blackTextColor: #000;
$navbarColor: #444444;
$primaryTextColor: #4d484a;
$blueTextColor: #1a76bc;
$buttonColor: #1d75bc;

// Borders Colors
$blueBorderColor:#4a91c9;
$headerBoxShadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2);
$achievedBoxShadow: 0 10px 20px rgba(0, 0, 0, 0.1);
// Frontend Colors //
